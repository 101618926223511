define("discourse/plugins/discourse-events/discourse/components/modal/add-event", ["exports", "@ember/component", "discourse/mixins/modal-functionality", "I18n", "@ember/object", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _modalFunctionality, _I18n, _object, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal @closeModal={{@closeModal}} class="add-event-modal" id="event-modal" @title={{i18n title}}>
    <:body>
    {{event-form
      event=model.event
      updateEvent=(action 'updateEvent')}}
    </:body>
    <:footer>
      {{d-button action=(action "saveEvent") class="btn-primary" label="add_event.event_add"}}
      <a href class="clear" {{on "click" (fn this.clear)}}>{{i18n 'add_event.event_clear'}}</a>
    </:footer>
  </DModal>
  
  */
  {
    "id": "9hKkKWGY",
    "block": "[[[8,[39,0],[[24,0,\"add-event-modal\"],[24,1,\"event-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[[30,0,[\"title\"]]],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n  \"],[1,[28,[35,2],null,[[\"event\",\"updateEvent\"],[[30,0,[\"model\",\"event\"]],[28,[37,3],[[30,0],\"updateEvent\"],null]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[1,[28,[35,4],null,[[\"action\",\"class\",\"label\"],[[28,[37,3],[[30,0],\"saveEvent\"],null],\"btn-primary\",\"add_event.event_add\"]]]],[1,\"\\n    \"],[11,3],[24,6,\"\"],[24,0,\"clear\"],[4,[38,5],[\"click\",[28,[37,6],[[30,0,[\"clear\"]]],null]],null],[12],[1,[28,[35,1],[\"add_event.event_clear\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `title` property path was used in the `discourse/plugins/discourse-events/discourse/components/modal/add-event.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.title}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-events/discourse/components/modal/add-event.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"event-form\",\"action\",\"d-button\",\"on\",\"fn\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/modal/add-event.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (_class = class _class extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "title", "add_event.modal_title");
    }
    clear() {
      event?.preventDefault();
      this.set("bufferedEvent", null);
    }
    saveEvent() {
      if (this.valid) {
        this.get("model.update")(this.bufferedEvent);
        this.closeModal();
      } else {
        this.flash(_I18n.default.t("add_event.error"), "error");
      }
    }
    updateEvent(event, valid) {
      this.set("bufferedEvent", event);
      this.set("valid", valid);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveEvent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateEvent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateEvent"), _class.prototype)), _class));
});