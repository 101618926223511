define("discourse/plugins/discourse-events/discourse/components/add-event-controls", ["exports", "discourse/lib/show-modal", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "discourse-common/utils/decorators", "@ember/component", "@ember/service", "discourse/plugins/discourse-events/discourse/components/modal/add-event", "@ember/object"], function (_exports, _showModal, _dateUtilities, _decorators, _component, _service, _addEvent, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let _class = _exports.default = (_dec = (0, _decorators.default)("noText"), _dec2 = (0, _decorators.default)("event"), _dec3 = (0, _decorators.default)("category", "noText"), (_class2 = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
    }
    didInsertElement() {
      $(".title-and-category").toggleClass("event-add-no-text", this.get("iconOnly"));
    }
    valueClasses(noText) {
      let classes = "add-event";
      if (noText) {
        classes += " btn-primary";
      }
      return classes;
    }
    valueLabel(event) {
      return (0, _dateUtilities.eventLabel)(event, {
        noText: this.get("noText"),
        useEventTimezone: true,
        showRsvp: true,
        siteSettings: this.siteSettings
      });
    }
    iconOnly(category, noText) {
      return noText || this.siteSettings.events_event_label_no_text || Boolean(category && category.get("custom_fields.events_event_label_no_text"));
    }
    showAddEvent() {
      this.modal.show(_addEvent.default, {
        model: {
          bufferedEvent: this.event,
          event: this.event,
          update: event => {
            this.set("event", event);
          }
        }
      });
    }
    removeEvent() {
      this.set("event", null);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "valueClasses", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "valueClasses"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "valueLabel", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "valueLabel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "iconOnly", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "iconOnly"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showAddEvent", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "showAddEvent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeEvent", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "removeEvent"), _class2.prototype)), _class2));
});